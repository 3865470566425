/**
 * @prettier
 */
import React from 'react';
import {graphql} from 'gatsby';
import ReactMarkdown from 'react-markdown';
import get from 'lodash/get';
import Container from '../components/Container';
import ContactForm from '../components/ContactForm';
import HeroBanner from '../components/HeroBanner';
import Page from '../components/Page';
import * as UI from '../components/UI/styles';

const ContactPage = ({data}) => {
    const page = get(data, 'page');

    return (
        <Page>
            <HeroBanner title={page.pageHeading} />

            <UI.ResponsiveSpacer size="s" sizeAtMobile="l" />

            <Container>
                <UI.LayoutContainer>
                    <UI.LayoutItem sizeAtMobile={6 / 12}>
                        <ReactMarkdown source={get(page, 'content.content')} />
                        <UI.Spacer size="s" />
                    </UI.LayoutItem>
                    <UI.LayoutItem sizeAtMobile={6 / 12}>
                        <ContactForm />
                    </UI.LayoutItem>
                </UI.LayoutContainer>
            </Container>
        </Page>
    );
};

export const query = graphql`
    query {
        page: contentfulPage(title: {eq: "Contact"}) {
            pageHeading
            content {
                content
            }
            resources {
                title
                references {
                    key
                    value {
                        value
                    }
                }
            }
        }
    }
`;

export default ContactPage;
