/**
 * @prettier
 * @flow
 */
import React from 'react';
import CTAButton from '../CTAButton';
import Link from '../Link';
import * as UI from '../UI/styles';
import * as S from './styles';

const ContactForm = () => (
    <form
        action="/thanks/"
        name="contact-form"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
    >
        <S.TextInput name="name" required placeholder="Full name" />
        <UI.Spacer size="s" />
        <S.TextInput name="email" type="email" required placeholder="Email" />
        <UI.Spacer size="s" />
        <S.TextArea name="message" required placeholder="Message" rows="8" />
        <UI.Spacer size="s" />
        <S.ButtonWrapper>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact-form" />
            <UI.VisuallyHidden aria-hidden="true">
                <Link to="/thanks/" />
            </UI.VisuallyHidden>
            <CTAButton type="submit">Send message</CTAButton>
        </S.ButtonWrapper>
    </form>
);

export default ContactForm;
